<template>
  <div class="controller">
    <div class="ad">
      <div class="ad-h1">
        {{ text }}
      </div>
      <div class="dog">
        <animation />
      </div>
    </div>
  </div>
</template>

<script>
import animation from "@/components/animation.vue";
export default {
  name: "AdView",
  components: { animation },
  mixins: [],
  props: {
    text: {
      type: String,
      default:
        "一群有爱心的小伙伴共同建立打造的公益团体，有自己的流浪动物救助基地宣传救助理念领养代替购买，给流浪动物撑起一个家",
    },
  },
  data() {
    return {};
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {},
  beforeUnmount() {},
  methods: {},
};
</script>

<style lang="less" scoped>
.ad {
  padding: 20px 50px;
  box-sizing: border-box;
  min-height: 400px;
  background: #00c1a2;
  display: flex;
  align-items: center;
  overflow: hidden;
  .ad-h1 {
    font-size: 36px;
    width: 60%;
    font-weight: 700;
    color: #fff;
    margin-right: 100px;
  }
}
</style>
