import Vue from "vue";
import VueRouter from "vue-router";
import IndexView from "@/views/index";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "index",
    component: IndexView,
  },
  {
    path: "/about",
    name: "about",
    component: () => import("@/views/about.vue"),
  },
  {
    path: "/thines",
    name: "thines",
    component: () => import("@/views/thines.vue"),
  },
  {
    path: "/cat",
    name: "cat",
    component: () => import("@/views/cat.vue"),
  },
  {
    path: "/dog",
    name: "dog",
    component: () => import("@/views/dog.vue"),
  },
  {
    path: "/detail",
    name: "detail",
    component: () => import("@/views/detail.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: "/web",
  routes,
});

export default router;
