<template>
  <div class="index_controller">
    <div class="carousel">
      <el-carousel>
        <el-carousel-item
          v-for="(item, index) in carouselList"
          :key="`carousel${index}`"
        >
          <img :src="item.picture" />
        </el-carousel-item>
      </el-carousel>
    </div>
    <div class="others">
      <img src="@/assets/images/index/others/1.jpg" />
    </div>
    <Ad text="每一个生命都值得尊重，爱护每一只动物。" />
  </div>
</template>

<script>
import Ad from "@/components/ad.vue";
export default {
  name: "IndexView",
  components: { Ad },
  mixins: [],
  props: {},
  data() {
    return {
      carouselList: [
        {
          picture: require("@/assets/images/index/carousel/1.jpg"),
        },
        {
          picture: require("@/assets/images/index/carousel/2.jpg"),
        },
        {
          picture: require("@/assets/images/index/carousel/3.jpg"),
        },
        {
          picture: require("@/assets/images/index/carousel/4.jpg"),
        },
      ],
    };
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {},
  beforeUnmount() {},
  methods: {},
};
</script>

<style lang="less" scoped>
.index_controller {
  width: 100%;
  overflow: hidden;

  .carousel,
  .others {
    width: 1600px;
    height: 600px;
    border-radius: 20px;
    overflow: hidden;
    margin: 20px auto;
    :deep(.el-carousel__container) {
      height: 600px;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .others {
    height: 800px;
  }

  .ad {
    background: #00c1a2;
    min-height: 100px;
    padding: 20px 50px;
    box-sizing: border-box;
    // display: flex;
    // justify-content: space-between;
    position: relative;
    .ad-h1 {
      font-size: 36px;
      font-weight: 700;
      color: #fff;
    }
    .dog {
      transform: scale(0.5);
      position: absolute;
      top: 5px;
      right: 147px;
    }
  }
}
</style>
