import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import {
  Container,
  Header,
  Main,
  Footer,
  Carousel,
  CarouselItem,
  Tag,
  Link,
} from "element-ui";

Vue.use(Container);
Vue.use(Header);
Vue.use(Main);
Vue.use(Footer);
Vue.use(Carousel);
Vue.use(CarouselItem);
Vue.use(Tag);
Vue.use(Link);

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
